import React from "react";
import { PaymentContextProvider } from "../components/PaymentDetail/PaymentContext";
import Spacer from "../components/Spacer/Spacer";
import BeeBoxStoreDashboard from "../components/BeeBoxStoreDashboard/BeeBoxStoreDashboard";

const BeeBoxStore = (props) => {
  const { parentUrl, data } = props.pageContext;
  const { labels } = data;

  return (
    <>
      <PaymentContextProvider
        labels={labels}
        parentUrl={parentUrl}
      >
        <BeeBoxStoreDashboard/>
        <Spacer size={10} />
      </PaymentContextProvider>
    </>
  );
};

export default BeeBoxStore;
